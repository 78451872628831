import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import api from "./modules/api";
import header from "./modules/header";
import config from "./modules/config";
import info from "./modules/info";
import permissions from "./modules/permissions";
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        api,
        header,
        config,
        info,
        permissions
    },
    getters
});

export default store;
